import React, { Component } from 'react';
import { Wrapper , Status} from "@googlemaps/react-wrapper";
import APP from "../redux/actions";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  APP: (payload) => dispatch(APP(payload))
});

const render = (status) => {
  switch (status) {
    case Status.LOADING:
    return <h3>{status} ..</h3>;
    case Status.FAILURE:
    return <h3>{status} ..</h3>;
    case Status.SUCCESS:
    return <Map style={{ flexGrow: "1", height: "100%"}}/>;
    default:
    return <h3>{status} ..</h3>;
  }
};



class GoogleMaps extends Component {


  constructor(props) {
    super(props);
    this.state = {
      clicks:[],
      items:this.props.items,
      zoom:this.getDevice()==="Desktop" ? 9 : 5,
      center: {
        lat: parseFloat(this.props.center.lat) ? parseFloat(this.props.center.lat) : 0,
        lng: parseFloat(this.props.center.lng) ? parseFloat(this.props.center.lng) : 0,
      },
    }
  }

  getDevice= ()=>{
    let detectDeviceType =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ? 'Mobile'
    : 'Desktop';
    // console.log(detectDeviceType);
     return detectDeviceType;

  }

  shouldComponentUpdate(nextProps) {
    if(nextProps.items.length!==this.props.items.length  || this.state.center.lat !== parseFloat(nextProps.configuration.position.lat) ||
    this.state.center.lng !== parseFloat(nextProps.configuration.position.lng))
      return true;
    else
       return false;
   }

  componentDidUpdate(propsPrecedenti) {
  // Utilizzo tipico (non dimenticarti di comparare le props):
// console.log(this.props.center);
// console.log(this.state.center.lat);
  if (this.state.center.lat !== parseFloat(propsPrecedenti.configuration.position.lat) ||
  this.state.center.lng !== parseFloat(propsPrecedenti.configuration.position.lng)) {
    // console.log("centro cambiato");
    this.setState({center:{
      lat: parseFloat(propsPrecedenti.configuration.position.lat) ? parseFloat(propsPrecedenti.configuration.position.lat) : 0,
      lng: parseFloat(propsPrecedenti.configuration.position.lng) ? parseFloat(propsPrecedenti.configuration.position.lng) : 0,
    }})
  }
}


  onClick = (e) => {
    console.log(e);

    // avoid directly mutating state
    this.setState({clicks:e.latLng});

    /*
    mapbound.contains(
      new window.google.maps.LatLng(
        parseFloat(el.location.coordinates[1]),
        parseFloat(el.location.coordinates[0])
      )
    )
    */
  }

  onIdle = (m) => {
    // console.log(m);
    // console.log(m.getBounds());
    let arrayView=[];
    for(let i=0;i<this.props.items.length;i++){
      if(m.getBounds() && m.getBounds().contains(new window.google.maps.LatLng(
        parseFloat(this.props.items[i].location.coordinates[1]),
        parseFloat(this.props.items[i].location.coordinates[0]))))
        arrayView.push(this.props.items[i]);
    }
    this.setState({zoom:m.getZoom(), items:arrayView});
    this.props.loadMarkers(arrayView);
  }

  openSwipeableDrawer = (item, i) =>{
    // console.log("openSwipeableDrawer 2"+JSON.stringify(item));
    this.props.openSwipeableDrawer(item, i);
  }



  getCoordinates = (item) =>{
    return {
      lat: item.location.coordinates[1],
      lng: item.location.coordinates[0],
    }
  }


  // [START maps_react_map_component_app_return]
  render(){
    return (
      <div style={{
        // height: "92vh",
        // height:`calc(100% - 64px)`,
        top:64,
        height: "91%",
        width: "100vw",
        position: "fixed",
        margin: 0 }}>
        <Wrapper apiKey={"AIzaSyCtAWW-Sv99CiDFq5i4cYgE_0UBAuQBwXg"} render={render}>
          <Map
            items={this.props.items}
            center={this.props.center}
            locPos={{lat:parseFloat(this.state.center.lat), lng:parseFloat(this.state.center.lng)}}
             onClick={this.onClick}
             loadMarkers={this.props.loadMarkers}
             onIdle={this.onIdle}
            zoom={this.state.zoom}
            streetViewControl= {false} // disables streetwiev icon only
            disableDefaultUI={this.getDevice()==="Desktop" ? false : true} // disables the whole ui streetview buttons
            style={{ flexGrow: "1", height: "100%"}}
            >


                <PositionStart key="posUser" position={this.state.center}
                  // onClick={()=>this.openSwipeableDrawer(item, i)}
                  // title={item.name}
                  // item={item}
                  // icon="https://maps.gstatic.com/mapfiles/ms2/micons/rangerstation.png"

                  icon="/images/map-home.png"
                  >
                  </PositionStart>

              {this.props.items.map((item, i) => (

                <Marker key={i} position={this.getCoordinates(item)}
                  onClick={()=>this.openSwipeableDrawer(item, i)}
                  item={item}
                  title={item.name}
                  // icon={item.type==="poi" ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png" : "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
                  icon={item.type==="poi" ? "/images/poi-icon.png" : "/images/path-icon.png"}
                  >
                  </Marker>


                ))}
              </Map>
            </Wrapper>

          </div>
        )};
        // [END maps_react_map_component_app_return]
      };

      const Map = ({ onClick, locPos, items, onIdle, loadMarkers, children, style, ...options }) => {
        // [START maps_react_map_component_add_map_hooks]
        const ref = React.useRef(null);
        const [map, setMap] = React.useState();

        React.useEffect(() => {
          if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
          }
        }, [ref, map]);
        // [END maps_react_map_component_add_map_hooks]
        // [START maps_react_map_component_options_hook]
        // because React does not do deep comparisons, a custom hook is used
        // see discussion in https://github.com/googlemaps/js-samples/issues/946
        useDeepCompareEffectForMaps(() => {
          if (map) {
            map.setOptions(options);
            let arrayView=[];
            for(let i=0;i<items.length;i++){
              if(map.getBounds() && map.getBounds().contains(new window.google.maps.LatLng(
                parseFloat(items[i].location.coordinates[1]),
                parseFloat(items[i].location.coordinates[0]))))
                arrayView.push(items[i]);
            }
            // this.setState({zoom:map.getZoom(), items:arrayView});
            loadMarkers(arrayView);
          }
        }, [map, options]);
        // [END maps_react_map_component_options_hook]
        // [START maps_react_map_component_event_hooks]
        React.useEffect(() => {
          if (map) {
            // new window.google.maps.Circle({
            //    strokeColor: "#FF0000",
            //    // strokeOpacity: 0.8,
            //    strokeWeight: 2,
            //    fillColor: "#FF0000",
            //    // fillOpacity: 0.35,
            //    map,
            //    center: locPos,
            //    radius: 100,
            //  });

            ["click", "idle"].forEach((eventName) =>
            window.google.maps.event.clearListeners(map, eventName)

          );

          if (onClick) {
      map.addListener("click", onClick);
    }

    if (onIdle) {
      map.addListener("idle", () => onIdle(map));
    }



    }
  }, [map, onClick, onIdle]);
  // [END maps_react_map_component_event_hooks]
  // [START maps_react_map_component_return]
  // const cityCircle = new window.google.maps.Circle({
  //     strokeColor: "#FF0000",
  //     strokeOpacity: 0.8,
  //     strokeWeight: 2,
  //     fillColor: "#FF0000",
  //     fillOpacity: 0.35,
  //     map,
  //     center: { lat: 40.714, lng: -74.005 },
  //     radius: Math.sqrt(8405837) * 100,
  //   });

  return (
    <>
    <div ref={ref} style={style} />
    {/* {cityCircle} */}
    {React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        // set the map prop on the child component
        return React.cloneElement(child, { map });
      }
    })}

  </>
);
// [END maps_react_map_component_return]
};

// const Circle = ({lat,lng,distance}) => {
//   const [map, setMap] = React.useState();
//   const [circle, setCircle] = React.useState();
//
//   const ref = React.useRef(null);
//   React.useEffect(() => {
//     if (ref.current && !map) {
//       setMap(new window.google.maps.Map(ref.current, {}));
//     }
//   }, [setMap,ref, map]);
//
//   React.useEffect(() => {
//     // console.log("circle "+circle);
//     if (!circle) {
//
//       setCircle(new window.google.maps.Circle(
//         {
//            strokeColor: "#FF0000",
//            // strokeOpacity: 0.8,
//            strokeWeight: 2,
//            fillColor: "#FF0000",
//            // fillOpacity: 0.35,
//            map,
//            center: {lat:42.10,lng:12.55},
//            radius: 100000,
//          }
//
//
//       ));
//
//     }
//
//
//
//     // remove marker from map on unmount
//     return () => {
//       if (circle) {
//         circle.setMap(null);
//       }
//     };
//   }, [circle, setCircle, map, lat,lng,distance]);
//   console.log("circle "+circle);
//   return null
//
//   // new window.google.maps.Circle({
//   //    strokeColor: "#FF0000",
//   //    strokeOpacity: 0.8,
//   //    strokeWeight: 2,
//   //    fillColor: "#FF0000",
//   //    fillOpacity: 0.35,
//   //    map,
//   //    center: this.state.center,
//   //    radius: Math.sqrt(distance) * 100,
//   //  });
//
//
//
//  }







 const PositionStart = ({ onClick, item, children, style, ...options }) => {
   const [marker, setMarker] = React.useState();

   React.useEffect(() => {
     if (!marker) {
       setMarker(new window.google.maps.Marker());
     }

     // remove marker from map on unmount
     return () => {
       if (marker) {
         marker.setMap(null);
       }
     };
   }, [marker]);
   React.useEffect(() => {

     if (marker) {
       marker.setOptions(options);
       marker.addListener("click", onClick);

     }

   }, [marker, onClick, options]);

   return null;
 };












// [START maps_react_map_marker_component]
const Marker = ({ onClick, item, children, style, ...options }) => {
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  React.useEffect(() => {

    if (marker) {
      marker.setOptions(options);
      marker.addListener("click", onClick);

    }

  }, [marker, onClick, options]);

  return null;
};

// [END maps_react_map_marker_component]
const deepCompareEqualsForMaps =
// createCustomEqual((deepEqual) => (a, b)
()=> {
  // if (
  //   isLatLngLiteral(a) ||
  //   a instanceof google.maps.LatLng ||
  //   isLatLngLiteral(b) ||
  //   b instanceof google.maps.LatLng
  // ) {
  // return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
  // }
  // TODO extend to other types
  // use fast-equals for other objects
  // return deepEqual(a, b);
}

function useDeepCompareMemoize(value) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  React.useEffect(
    callback,
    dependencies.map(useDeepCompareMemoize));
  }
  //
  // window.addEventListener("DOMContentLoaded", () => {
  //   const root = createRoot(document.getElementById("root"));
  //
  //   root.render(<App />);
  // });
  // // [END maps_react_map]
  export default connect(mapStateToProps, mapDispatchToProps)(GoogleMaps);
