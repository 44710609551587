import { createStore } from "redux";
import { initialState, core} from "./statecore";

export const configureStore = function configureStore(state = initialState) {
  let params = new URLSearchParams(window.location.search);
  let appname = params.get("app");
  let domainname = params.get("domain");

  if (!appname) {
    appname = window.localStorage["currentapp"];
  }

  if (!domainname) {
    appname = window.localStorage["currentdomain"];
  }

  if (appname) {
    window.localStorage["currentapp"] = appname;
  }

  if (domainname) {
    window.localStorage["currentdomain"] = domainname;
  }

  initialState.app=appname;
  initialState.domain=domainname;
  const store = createStore(core, initialState);
   return store

};

export default configureStore;
