import React, { Component } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import TextField from '@mui/material/TextField';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MyLocationIcon from '@mui/icons-material/MyLocation';
// import SearchIcon from '@mui/icons-material/Search';
import DrawerList from './DrawerList';
import ContentList from './ContentList';
import MapList from './MapList';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import {
  itemsSort,
  itemsSortInverse,

} from "../utils/utility";

import {
  getService,
} from "../services/services";
// import * as services from "../services/services.json";

import APP from "../redux/actions";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  APP: (payload) => dispatch(APP(payload))
});

const iOS =
typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

const drawerWidth = 160;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  // minHeight: 64,
  justifyContent: 'flex-end',
}));

const theme = createTheme();

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openDrawer:false,
      viewDrawer:"Content",
      items: [],
      progress:true,
      iconposition: false,
      viewSlider:false,
      value: 700000,
      device:"Desktop",
      namedown: false,
      nameup: false,
      searchValue: "",
      orderSelected: "",
      rightSelected: "",
      filtersarray: [],
      originalItems:[],
      poifilter:false,
      pathfilter:false,
      scrollY:false,
      positionenabled:false,
      // displayAlert:false,
      openSnackPosition:false,
      showAlert:false
    }
  }

  componentDidMount() {
    this.getDevice();
    this.getResources();
  }


  handleChangeArea = (event, newValueArea) => {
    this.setState({value:newValueArea});

  };



  getResources = () => {

    let self = this;
    let ser =
    process.env.REACT_APP_DOMAIN + "/mobile/searchPOIByGravity?appname={APPNAME}&domain={DOMAIN}&lat={LAT}&lon={LNG}&limit={LIMIT}&distance={DISTANCE}&dest={DEST}";

    ser = ser.replace(
      "{APPNAME}",
      this.props.app.toUpperCase()
    );
    ser = ser.replace("{DOMAIN}", this.props.domain);
    ser = ser.replace("{DEST}", "resource");
    ser = ser.replace("{LAT}", this.props.configuration.position.lat);
    ser = ser.replace("{LNG}", this.props.configuration.position.lng);
    ser = ser.replace("{LIMIT}", 20);
    ser = ser.replace("{DISTANCE}", this.state.value);
    getService(ser, "", function (err, result) {
      // console.log("result "+result.status);

      if (err) {
        // console.log("err "+err);
        return;
      }
      if (result.status === 200) {
        console.log("result"+result.response.length);
        self.setState({ items: result.response, originalItems: result.response, progress:false });
        return;
      }
      if (result.status === 204) {
        self.setState({ progress:false, items: []});
        //array vuoto
      }

      return;
    });
  };

  closeAlert = () => {
    this.setState({showAlert:false});
  };



  setOpenContent = (text) =>{
    // console.log("setOpenContent");
    this.setState({viewDrawer:text});
    this.handleDrawerClose();
  }

  handleDrawerOpen = () => {
    this.setState({openDrawer:true});
    this.setState({viewSlider:false});
  }

  handleDrawerClose = () => {
    this.setState({openDrawer:false});
    this.setState({viewSlider:true});
  }


  getLocationUser = () => {

    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(this.showPosition, this.errorPosition);
    } else {
      // console.log("Geolocation is not supported by this browser.");//all'inizio e se non supportato bisogna posizionarsi a Roma
    }


  }

  errorPosition = (error) => {
    // console.log(error);
    if (error.code === error.PERMISSION_DENIED) {
      this.setState({iconposition:true});
    }

  }

  showPosition = (position) => {
    this.props.configuration.position.lat=parseFloat(position.coords.latitude);
    this.props.configuration.position.lng=parseFloat(position.coords.longitude);
    this.setState({positionenabled:true});
    this.setState({showAlert:true});

    this.props.APP(this.props);

    console.log(
      "Latitude: " + position.coords.latitude + "<br>" +
      "Longitude: " + position.coords.longitude);
      // console.log(this.props);
      this.getResources();
    }

    getDevice= ()=>{
      let detectDeviceType =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ? 'Mobile'
      : 'Desktop';
      // console.log(detectDeviceType);
      // return detectDeviceType;
      this.setState({device:detectDeviceType});
    }

    searchInput = (event) => {
      if (event.target.value.length > 0) {

        let filtered = this.state.originalItems.filter(
          function (el, index, arr) {
            let found = false;
            // let activeFilter = [];
            if (
              el.name
              .toUpperCase()
              .indexOf(event.currentTarget.value.toUpperCase()) !== -1
            ) {
              // activeFilter.push("name");
              found = true;
            }

            return found;
          }
        );
        this.setState(
          { items: filtered, searchValue: event.target.value },
          () => {
            this.filterengine(
              undefined,
              undefined,
              undefined,
              this.state.rightSelected
            );
          }
        );
      } else {
        this.setState(
          {
            items: this.state.originalItems,
            searchValue: event.target.value,
          },
          () => {
            this.filterengine(
              undefined,
              undefined,
              undefined,
              this.state.rightSelected
            );
          }
        );
      }
    };


    filterengine = (type, flag, mapbound, rightSel) => {
      let filarr = this.state.filtersarray.slice(
        0,
        this.state.filtersarray.length
      );

      if (type) {
        for (var i = 0; i < type.length; i++) {
          let indexel = filarr.indexOf(type[i]);
          if (indexel !== -1) {
            filarr.splice(indexel, 1);
          }
          if (flag === true) {
            filarr.push(type[i].toLowerCase());
          }
        }
      }

      // console.log(type);
      // console.log(filarr);

      var filtered = [];
      var copyResources = [];
      let self=this;
      if (this.state.searchValue !== "") {
        copyResources = this.state.originalItems.slice();
        copyResources = copyResources.filter(
          function (el, index, arr) {
            let found = false;
            // let activeFilter = [];
            if (
              el.name.toUpperCase()
              .indexOf(self.state.searchValue.toUpperCase()) !== -1
            ) {
              // activeFilter.push("name");
              found = true;
            }


            return found;
          });
        } else {
          copyResources = this.state.originalItems.slice(
            0,
            this.state.originalItems.length
          );
        }
        // console.log(copyResources.length);
        //let self = this;
        if (filarr.length > 0 && rightSel === "") {
          //one or more filter activated
          for(let i=0;i<filarr.length;i++){
            let filterArray = copyResources.filter(el =>
              filarr[i]===el.type);
              // console.log(filterArray.length);

              filtered=filtered.concat(filterArray);
            }


            // console.log(filtered);

            this.setState(
              {
                items: self.order(self.state.orderSelected, false, filtered),
                filtersarray: filarr,
                rightSelected: rightSel,
              }
            );
          }
          else{
            filtered=copyResources;
            this.setState(
              {
                items: self.order(self.state.orderSelected, false,filtered),
                filtersarray: filarr,
                rightSelected: rightSel,
              }
            );
          }

        };

        tabOrder = (event) => {
          this.order(event.currentTarget.name, true, this.state.items);
        }

        order = (orderType, orderByInterface, arrayToOrder) => {
          // console.log("order"+orderType);
          let data = arrayToOrder.slice();
          let orderArray = [];

          switch (orderType) {
            case "nameup":
            orderArray = itemsSort("name", data);
            if (orderByInterface) {
              this.setState({
                datedown: false,
                dateup: false,
                namedown: false,
                nameup: true,
                items: orderArray,
                orderSelected: "nameup",
              });
            } else {
              return orderArray;
            }
            break;
            case "namedown":
            orderArray = itemsSortInverse("name", data);
            if (orderByInterface) {
              this.setState({
                datedown: false,
                dateup: false,
                namedown: true,
                nameup: false,
                items: orderArray,
                orderSelected: "namedown",
              });
            } else {
              return orderArray;
            }
            break;

            default:
            if (orderByInterface) {
              this.setState({
                datedown: false,
                dateup: false,
                namedown: true,
                nameup: false,
                items: this.state.originalItems,
                orderSelected: "",
              });
            } else {
              return data;
            }

            break;
          }
        }


        filterselection = (event, value) => {
          switch (event.currentTarget.name) {
            case "poi":
            this.setState(
              { poifilter: this.state.poifilter === true ? false : true },
              () => {
                this.filterengine(
                  ["poi"],
                  this.state.poifilter,
                  undefined,
                  this.state.rightSelected
                );
              }
            );
            break;
            case "path":
            this.setState(
              { pathfilter: this.state.pathfilter === true ? false : true },
              () => {
                this.filterengine(
                  ["path"],
                  this.state.pathfilter,
                  undefined,
                  this.state.rightSelected
                );
              }
            );
            break;

            default:
            break;
          }
        };

        // handleScroll=(e)=>{
        //   // console.log(e.deltaY);
        //   if(e.deltaY>70){
        //     this.setState({scrollY:true})
        //   }
        //
        //   else{
        //     this.setState({scrollY:false})
        //   }
        // }


        checkDevice = () => {
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
            // Take the user to a different screen here.
            // console.log(navigator.userAgent)
            return 'Mobile'

          }else return "Desktop"
        }

        render(){


          return(
            <div id="back-top">

              <ThemeProvider theme={theme}>
                <Box sx={{ display: 'flex' }}> {/*box che contiene icona drawer allineata a sinistra*/}
                <CssBaseline />

                <AppBar position="fixed" open={this.state.openDrawer}
                  sx={{
                    // backgroundColor:"#FFFFFF09"
                    background: this.state.scrollY===false ?  "#FFFFFF09" : "#FFF",
                  }}
                  >
                    <Toolbar>
                      <IconButton
                        color="primary"
                        aria-label="open drawer"
                        onClick={this.handleDrawerOpen}
                        edge="start"
                        sx={{
                          backgroundColor: '#FFFFFF99',
                          mr: 1,
                          ...(this.state.openDrawer && { display: 'none' })
                        }}
                        >
                          <MenuIcon/>
                        </IconButton>
                        {/* <Box
                          component="img"
                          sx={{
                          height: 64,
                          mr:1,
                        }}
                        alt="logo ideha"
                        src="./images/loghi/logo_ideha.png"
                      /> */}
                      <Typography variant="h6" noWrap component="div">
                        {/* Persistent drawer */}
                      </Typography>
                      {/* <Search>
                        <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </Search> */}

                  <Box display='flex' flexGrow={1}>
                    {/* //sinistra */}
                  </Box>
                  <IconButton
                    sx={{
                      backgroundColor: '#FFFFFF99'
                    }}
                    color={this.state.positionenabled === true ? "success" : "primary"}
                    disabled={(this.state.iconposition === true)}
                    aria-label="geolocation"
                    onClick={this.getLocationUser}
                    >
                      <Tooltip title="Allow Location Access">
                        <MyLocationIcon/>
                      </Tooltip>
                    </IconButton>
                  </Toolbar>

                </AppBar>

                <Snackbar
                  open={this.state.showAlert}
                  autoHideDuration={4000}
                  onClose={this.closeAlert}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                  <Alert onClose={this.closeAlert} sx={{ width: '100%', backgroundColor:"#348138", color:"#FFF" }} elevation={8} variant="filled">
                    Location permitted!
                  </Alert>
                </Snackbar>


                <Main
                  style={{padding:"0px"}}
                  open={this.state.openDrawer}
                  >
                    <DrawerHeader />
                    {this.state.viewDrawer==="Content" ?
                    <ContentList items={this.state.items} progress={this.state.progress} 
                    positionenabled={this.state.positionenabled}/>
                    :
                    <MapList items={this.state.items}/>
                  }
                </Main>
                <SwipeableDrawer disableBackdropTransition={!iOS} disableDiscovery={iOS}
                  sx={{
                    width: drawerWidth,
                    padding:0,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                      width: drawerWidth,
                      boxSizing: 'border-box',
                    },
                  }}
                  //  variant= {this.checkDevice()==="Mobile" ? "persistent" :"persistent"}
                  anchor="left"
                  open={this.state.openDrawer}
                  onClose={this.handleDrawerClose}
                  onOpen={this.handleDrawerOpen}
                  disableSwipeToOpen={false}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  >
                    <DrawerHeader>
                      <IconButton
                        color="primary"
                        onClick={this.handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                      </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <DrawerList openContent={this.setOpenContent}/>


                  </SwipeableDrawer>

                </Box>

              </ThemeProvider>
            </div>
          )
        }

      }

      export default connect(mapStateToProps, mapDispatchToProps)(Home);
