import React, { Component } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MapIcon from '@mui/icons-material/Map';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
// import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
// import Tooltip from '@mui/material/Tooltip';

class DrawerList extends Component {

  onClickList = (index, text) => {
    // if(text==="Map"){
    //
    // }
    // else {
    //
    // }
    this.props.openContent(text);
  }


  render(){
    return (
      <>
      <List>

        <ListItem key="Content" disablePadding>
          <ListItemButton onClick={()=>this.onClickList(0, "Content")}>
            <ListItemIcon>
              <ViewModuleIcon
                // color="primary"
              />
            </ListItemIcon>
            <ListItemText
                disableTypography
                primary={<Typography type="body2" style={{
                  // color: '#1976d2'
                  color: '#000'
                }}>HOME</Typography>}/>
          </ListItemButton>
        </ListItem>
        <ListItem key="Map" disablePadding>
          <ListItemButton onClick={()=>this.onClickList(1, "Map")}>
            <ListItemIcon>
              <MapIcon
                // color="primary"
              />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={<Typography type="body2" style={{
                // color: '#1976d2'
                color: '#000'
              }}>MAP</Typography>}/>
            </ListItemButton>
          </ListItem>
        </List>
        {/* <Divider />
          <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
          <ListItemButton>
          <ListItemIcon>
          {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  ))}
</List> */}
</>
)
}
}

export default DrawerList;
