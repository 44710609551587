const APP = (appstate) => {
  // Return action
  return {
    // Unique identifier
    type: "APP",
    // Payload
    payload: appstate,
  };
}

export default APP;
