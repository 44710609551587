export const initialState = {
  "app": "appmobile",
  "domain":"",
  "configuration": {
    "title": "titolo",
    "position": {
      lat:"41.8966277",
      lng:"12.4821685"
    },
    "Languages": {
      "default": "en",
      "list": [
        {
          "code": "en",
          "label": "EN - English",
          "icon": ""
        },
        {
          "code": "it",
          "label": "IT - Italiano",
          "icon": ""
        },
        {
          "code": "es",
          "label": "ES - Español",
          "icon": ""
        },
        {
          "code": "gr",
          "label": "GR - Ελληνικά",
          "icon": ""
        },
        {
          "code": "pl",
          "label": "PL - Polskie",
          "icon": ""
        }
      ]
    }
  }
};

export function core(state = initialState, action) {
  switch (action.type) {
    case 'appstate':
      return  Object.assign({}, state, action.payload);
    default:
      return state
  }
}

export default {
  appstate: core
};
