import React, { Component } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
  CardActionArea,
  // Button
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';


class ListScrollToItem extends Component {

  constructor(props) {
    super(props);
  this.scrollableListRef = React.createRef();
  // this.refs = this.props.list.reduce((acc, value) => {
  //   // console.log(acc);
  //   // console.log(value);
  //    acc[value._id] = React.createRef();
  //    // console.log(acc);
  //    return acc;
  //
  //  }, {});
  this.state = {
    singleItem:this.props.singleItem,
    itemsView:this.props.list,
    refsItem:[],
  }
}

componentDidMount(){
  // console.log("componentDidMount");
  this.refs = this.state.itemsView.reduce((acc, value) => {
    // console.log(acc);
    // console.log(value);
     acc[value._id] = React.createRef();
     // console.log(acc);
     return acc;

   }, {});
   this.setState({refsItem:this.refs});
     // console.log(this.refs);
}


  componentDidUpdate(propsPrecedenti) {
    // Utilizzo tipico (non dimenticarti di comparare le props):
    if (this.state.itemsView!== this.props.list)  {
    // this.state.singleItem!=propsPrecedenti.singleItem){
      // console.log("centro cambiato");
      this.refs = this.props.list.reduce((acc, value) => {
        // console.log(acc);
        // console.log(value);
         acc[value._id] = React.createRef();
         // console.log(acc);
         return acc;

       }, {});
         // console.log(this.refs);
      this.setState({
        itemsView:this.props.list,
        singleItem:this.props.singleItem
      })

     }
  }

   handleClick = (item) =>{
   // console.log(item);
   this.refs[item._id].current.scrollIntoView({
     behavior: 'smooth',
     block: 'start',
     inline: 'center'
   });


   console.log(this.scrollableListRef.current.scrollLeft);
   this.setState({singleItem:item});


   setTimeout(() => {
  // console.log('The browser has (theoretically) finished scrolling');
  this.props.check();
}, 800)
  }


  render(){
    // console.log(this.refs);

  return (
    <div>


          <List
              id="container"
              ref={this.scrollableListRef}
              sx={{
                flexWrap: "nowrap",
                flexDirection: 'row',
                display: "flex",
                overflowY: "scroll",
                overflow:"hidden",
            //    left: "-16px",
                // width:"100%"
              }}>


                {this.props.list.length>0 && this.refs!=={} && this.props.list.map((item, index)=>(

                  <ListItem
                    key={index}
                    ref={this.refs[item._id]}
                    sx={{
                      width:"250px",
                      height:"220px",
                      ml:2,
                      padding:0,
                    }}
                    >
                      <div>
                        <Card onClick={()=>this.props.openCard(item)}
                          sx={{
                            border:  this.props.singleItem._id===item._id ? "4px solid blue" : "1px solid gray",
                            position:"relative",
                            width: this.props.singleItem._id===item._id ? "260px" : "250px",
                            height:this.props.singleItem._id===item._id ? "210px" : "200px",
                            marginBottom: this.props.singleItem._id===item._id ? "20px" : "0px",
                          }}
                          >


                            <CardMedia
                              // sx={{
                              //
                              //     '&:hover': {
                              //       backgroundColor: 'primary.main',
                              //       opacity: [0.9, 0.8, 0.7],
                              //     },
                              // }}
                              component="img"
                              height="100%"
                              width="100%"
                              image={
                                item.uriCoverExternalRes === '' && item.type === "poi"
                                ? "/images/poi.jpg"
                                :
                                item.uriCoverExternalRes === '' && item.type === "path"
                                ? "/images/path.jpg"
                                : item.uriCoverExternalRes
                              }
                              alt={item.name}
                            />

                            <CardActionArea sx={{
                              textAlign: 'center',
                              position:"absolute",
                              top:"0",
                              height:"100%",
                              width:"100%",
                              // marginTop: '-110px',
                              backgroundColor: "#00000075",
                              '&:hover': {
                                backgroundColor: 'primary.main',
                                opacity: [0.9, 0.8, 0.7],
                              },

                              // width:"100%",
                              // height:"100%",
                            }}>
                            <Typography color="white" >
                              {this.props.truncate(item.name,100)}
                            </Typography>
                            <br/>


                            {this.props.getUrl(item).includes("cuhet-backend2.net/") ?
                            <img
                              alt="panorama"
                              src="/images/panoWhite.png"
                              style={{
                                position: "relative",
                                width: "36px",
                                maxHeight: "36px",
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                              }}
                            />


                            : <VisibilityIcon fontSize="medium" sx={{ color: "white" }} /> }

                          </CardActionArea>
                        </Card>
                      </div>
                    </ListItem>
                  ))}
                </List>


    </div>
  )};
};
export default ListScrollToItem;
