import React, { Component } from 'react';
import GoogleMaps from './GoogleMaps';
import ListScrollToItem from './ListScrollToItem';
// import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// import { Global } from '@emotion/react';
import APP from "../redux/actions";
import { connect } from "react-redux";
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
  CardActionArea,
  // Button
} from '@mui/material';
import Grid from '@mui/material/Grid';
// import Tooltip from '@mui/material/Tooltip';
// import Stack from '@mui/material/Stack';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  getResourceDataForLanguage,
} from "../utils/utility";

import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  APP: (payload) => dispatch(APP(payload))
});
// const drawerBleeding = '56';

class MapList extends Component {

  constructor(props) {
    super(props);

    this.child = React.createRef();
    // this.scrollableListRef = React.createRef();

    // this.scrollableListRef =this.props.items.reduce((acc, value) => {
    //     acc[value._id] = React.createRef();
    //     return acc;
    //   }, {});
    //
    //   console.log(this.scrollableListRef)

    this.state = {
      openSwipeableDrawer:false,
      singleItem:{},
      disabledLeft:false,
      disabledRight:false,
      device:"Desktop",
      activeStep:0,
      itemsView:this.props.items,
      lat: parseFloat(this.props.configuration.position.lat),
      lng: parseFloat(this.props.configuration.position.lng),


    }
  }

  componentDidMount(){
    //console.log(this.scrollableListRef.current);
    this.getDevice();
    this.check();
  }

  componentDidUpdate(propsPrecedenti) {
    // Utilizzo tipico (non dimenticarti di comparare le props):
    // console.log(propsPrecedenti.configuration.position.lat);
    // console.log(this.props.configuration.position.lat);
    // console.log(this.state.center.lat);
    // this.props.configuration.position.lat
    if (this.state.lat!== parseFloat(propsPrecedenti.configuration.position.lat) ||
    this.state.lng !== parseFloat(propsPrecedenti.configuration.position.lng)) {
      // console.log("centro cambiato");
      this.setState({
        lat: parseFloat(propsPrecedenti.configuration.position.lat) ? parseFloat(propsPrecedenti.configuration.position.lat) : 0,
        lng: parseFloat(propsPrecedenti.configuration.position.lng) ? parseFloat(propsPrecedenti.configuration.position.lng) : 0,
        itemsView:this.props.items,

      })

    }
  }


  toggleDrawer = (status) => {
    this.setState({openSwipeableDrawer:status});
  }

  openItemDrawer = (item, index) =>{
    // console.log(">>> ITEM: "+JSON.stringify(item));
    // console.log("openItemDrawer "+item.name);
    this.placeSelectedItemInTheMiddle(index, item);
    this.setState({singleItem:item}, this.toggleDrawer(true));
  }

  getUrl = (item) => {
    let selectedUri= item.url;
    let url="";
    if (typeof(selectedUri)==='string'){ //retrocompatibilità
      url = selectedUri;
    }else {
      let itemHELP={};
      itemHELP.medialan=selectedUri;
      let lang =  getResourceDataForLanguage(itemHELP, "en");
      url = selectedUri[lang];
    }
    return url;
  }

  getDistance = (item) =>{
    // console.log("getDistance "+JSON.stringify(item.location));
    // console.log("ROMA lat x2: "+this.props.configuration.position.lat);
    // console.log("ROMA lng y2: "+this.props.configuration.position.lng);
    // let x2=this.props.configuration.position.lat;
    // let y2=this.props.configuration.position.lng;
    // let x1=item.location.coordinates[1];
    // let y1=item.location.coordinates[0];
    // let y = x2 - x1;
    // let x = y2 - y1;
    //
    // return Math.sqrt(x * x + y * y);


    // ============================================================
    // FROM: https://www.movable-type.co.uk/scripts/latlong.html
    // This uses the ‘haversine’ formula to calculate the great-circle distance between two points – that is, the shortest distance over the earth’s surface – giving an ‘as-the-crow-flies’ distance between the points (ignoring any hills they fly over, of course!).
    // Haversine
    // formula: 	a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
    // c = 2 ⋅ atan2( √a, √(1−a) )
    // d = R ⋅ c
    // where 	φ is latitude, λ is longitude, R is earth’s radius (mean radius = 6,371km);
    // note that angles need to be in radians to pass to trig functions!
    // JavaScript:
    // const R = 6371e3; // metres
    // const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    // const φ2 = lat2 * Math.PI/180;
    // const Δφ = (lat2-lat1) * Math.PI/180;
    // const Δλ = (lon2-lon1) * Math.PI/180;
    //
    // const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
    // Math.cos(φ1) * Math.cos(φ2) *
    // Math.sin(Δλ/2) * Math.sin(Δλ/2);
    // const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    //
    // const d = R * c; // in metres
    // ============================================================


    let R = 6371e3; // metres
    let φ1 = this.props.configuration.position.lat * Math.PI/180; // φ, λ in radians
    let φ2 = item.location.coordinates[1] * Math.PI/180;
    let Δφ = (item.location.coordinates[1]-this.props.configuration.position.lat) * Math.PI/180;
    let Δλ = (item.location.coordinates[0]-this.props.configuration.position.lng) * Math.PI/180;

    let a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ/2) * Math.sin(Δλ/2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    let d = R * c; // in metres

    let KM = (Math.round(d) /1000) ;
    // return d.toFixed(0)/1000;
    return KM
  }

  placeSelectedItemInTheMiddle = (index, item) => {
    if(this.state.device==="Desktop"){
      // const LIST_ITEM_HEIGHT = this.state.itemsView.length;
      // // const NUM_OF_VISIBLE_LIST_ITEMS = 5;
      // let amountToScroll = 0;
      // if(index===0){
      //   amountToScroll = 0;
      // }else if(index===LIST_ITEM_HEIGHT-1){
      //   amountToScroll =
      //   LIST_ITEM_HEIGHT * (index * 20) ;
      // }else{
      //   //this.scrollableListRef.current.clientWidth
      //   amountToScroll =
      //   LIST_ITEM_HEIGHT * (index *16) ;
      // amountToScroll =
      // LIST_ITEM_HEIGHT * index /this.scrollableListRef.current.clientWidth


      //
      // let offset =  this.scrollableListRef.current.scrollWidth-this.scrollableListRef.current.clientWidth-this.scrollableListRef.current.scrollLeft;
      //
      // amountToScroll =
      // this.scrollableListRef.current.scrollWidth / this.scrollableListRef.current.clientWidth + (NUM_OF_VISIBLE_LIST_ITEMS*300);
      this.child.current.handleClick(item);

      // console.log(index);
      // console.log(this.child.current);
      // this.child.current.handleClick(item);
      // if (this.child.current.scrollableListRef.current !== null)
      // this.scrollableListRef.current.scrollTo(amountToScroll, 0);
      // this.child.current.scrollableListRef[item._id].current.scrollIntoView({
      //       behavior: 'smooth',
      //       block: 'start',
      //     });
      // this.check();
    }
    else {
      let i = this.state.itemsView.indexOf(item);
      // console.log(i);
      this.setState({activeStep:i});
    }
  }

  scrollLeft = () =>{
    //  console.log("scrollLeft");
    //  console.log(this.child.current.scrollableListRef.current);
    if (this.child.current.scrollableListRef.current !== null)
    this.child.current.scrollableListRef.current.scrollTo(this.child.current.scrollableListRef.current.scrollLeft-300, 0);

    // console.log(this.child.current.scrollableListRef.current.scrollWidth,
    //   this.child.current.scrollableListRef.current.scrollLeft,
    //   this.child.current.scrollableListRef.current.clientWidth);


    this.check();
    //sei in top quando
    //this.scrollableListRef.current.scrollLeft=0

  }

  scrollRight = () =>{
    // console.log("scrollRight");
    // console.log(this.child.current.scrollableListRef.current);
    if (this.child.current.scrollableListRef.current !== null)
    this.child.current.scrollableListRef.current.scrollTo(this.child.current.scrollableListRef.current.scrollLeft+300, 0);

    // console.log(this.child.current.scrollableListRef.current.scrollWidth,
    //   this.child.current.scrollableListRef.current.scrollLeft,
    //   this.child.current.scrollableListRef.current.clientWidth);

    //bottom è dato da
    //this.scrollableListRef.current.scrollWidth-this.scrollableListRef.current.clientWidth
    //quindi quando this.scrollableListRef.current.scrollLeft=
    //this.scrollableListRef.current.scrollWidth-this.scrollableListRef.current.clientWidth
    //sei alla fine

    this.check();

  }

  check = () =>{
    // console.log(this.child.current.scrollableListRef);
    // console.log(this.child.current.scrollableListRef.current.scrollLeft);
    if(this.child.current.scrollableListRef!=null && this.child.current.scrollableListRef.current!=null){
      let offset =  this.child.current.scrollableListRef.current.scrollWidth-this.child.current.scrollableListRef.current.clientWidth-this.child.current.scrollableListRef.current.scrollLeft;
      // console.log(offset);
      this.setState({disabledLeft:this.child.current.scrollableListRef.current.scrollLeft===0 || this.child.current.scrollableListRef.current.scrollLeft<=16? true : false,
        disabledRight:offset<=0 ? true : false})
      }
    }

    openCard = (item) =>{
      let selectedUri= item.url;
      let url="";
      if (typeof(selectedUri)==='string'){ //retrocompatibilità
        url = selectedUri;
      }else {
        let itemHELP={};
        itemHELP.medialan=selectedUri;
        let lang =  getResourceDataForLanguage(itemHELP, "en");
        url = selectedUri[lang];
      }


      let detectDeviceType =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ? 'Mobile'
      : 'Desktop';
      console.log(detectDeviceType); // "Mobile" or "Desktop"

      if(detectDeviceType==="Desktop")
      window.open(url, "_blank");
      else
      window.open(url, "_self");
    }

    getDevice= ()=>{
      let detectDeviceType =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ? 'Mobile'
      : 'Desktop';
      // console.log(detectDeviceType);
      // return detectDeviceType;
      this.setState({device:detectDeviceType});
    }


    truncate = (str, max) =>{
      return str.length > max ? str.substr(0, max-1) + '…' : str;
    }

    handleNext = () => {
      this.setState({activeStep :this.state.activeStep + 1});
    };

    handleBack = () => {
      this.setState({activeStep :this.state.activeStep - 1});
    };

    handleStepChange = (step) => {
      this.setState({activeStep:step});
    };

    loadMarkers = (items) =>{
      this.setState({itemsView:items, activeStep:0, singleItem:{}});
    }

    render(){

      return(
        <>

        {/* <Global
          styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
          height: `calc(50% - ${drawerBleeding}px)`,
          overflow: 'visible',
        },
      }}
    /> */}
    <GoogleMaps
      // center={{
      //   lat:parseFloat(this.props.configuration.position.lat),
      //   lng:parseFloat(this.props.configuration.position.lng)
      // }}
      style={{zIndex:1}}
      items={this.props.items}
      center={{lat:this.state.lat, lng:this.state.lng}}
      openSwipeableDrawer = {
        this.openItemDrawer
      }
      loadMarkers={this.loadMarkers}/>

      {this.state.itemsView.length>0 && this.state.device==="Desktop" ?

      <Grid container
        justifyContent="center"
        spacing={0}
        columns={this.state.device==="Desktop" ? 16 : 5}
        sx={{
          position: 'absolute',
          bottom:"0%",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          visibility: 'visible',
          right: 0,
          left: 0,
          width:"98%",
          textAlign:"center",
          // paddingLeft: 0,
        }}
        >
          <Grid item xs={1} sx={{
            // position: "relative",
            // right:"20px",
          }}>
          {this.state.itemsView.length>4 &&
            <IconButton
              color="primary"
              aria-label="ArrowBack"
              component="label"
              size="large"
              sx={{
                top: "65px",
              }}
              variant="contained"
              onClick={() => this.scrollLeft()}
              disabled={this.state.disabledLeft}
              >
                <ArrowBackIosNewIcon
                  fontSize="large"
                  sx={{
                    borderRadius: 50,
                    backgroundColor: "white",
                    height:90
                  }}/>
                </IconButton>
              }
            </Grid>


            <Grid item xs={ this.state.device==="Desktop" ? 14 : 3}>
              {this.state.itemsView.length>0 &&
                <ListScrollToItem
                  ref={this.child}
                  list={this.state.itemsView}
                  singleItem={this.state.singleItem}
                  truncate={this.truncate}
                  openCard={this.openCard}
                  check={this.check}
                  getUrl={this.getUrl}/>}
                  {/*  <List
                    id="container"
                    // ref={this.scrollableListRef}
                    sx={{
                    flexWrap: "nowrap",
                    flexDirection: 'row',
                    display: "flex",
                    overflowY: "scroll",
                    overflow:"hidden",
                    left: "-16px",
                    // width:"100%"
                  }}>


                  {this.state.itemsView.length>0 &&this.state.itemsView.map((item, index)=>(

                  <ListItem
                  ref={this.scrollableListRef[item._id]}
                  sx={{
                  width:"250px",
                  height:"220px",
                  ml:2,
                  padding:0,
                }}
                >
                <div>
                <Card onClick={()=>this.openCard(item)}
                sx={{
                border:  this.state.singleItem._id===item._id ? "4px solid blue" : "1px solid gray",
                // flexBasis: "20%",
                // padding:"5px",
                // flexShrink: 0,
                position:"relative",
                width:"250px",
                height:"200px",
                //backgroundColor: this.state.singleItem._id===item._id ? "lightblue" : "trasparent"
              }}>


              <CardMedia
              // sx={{
              //
              //     '&:hover': {
              //       backgroundColor: 'primary.main',
              //       opacity: [0.9, 0.8, 0.7],
              //     },
              // }}
              component="img"
              height="100%"
              width="100%"
              image={
              item.uriCoverExternalRes === '' && item.type === "poi"
              ? "/images/poi.jpg"
              :
              item.uriCoverExternalRes === '' && item.type === "path"
              ? "/images/path.jpg"
              : item.uriCoverExternalRes
            }
            alt={item.name}
          />

          <CardActionArea sx={{
          textAlign: 'center',
          position:"absolute",
          top:"0",
          height:"100%",
          width:"100%",
          // marginTop: '-110px',
          backgroundColor: "#00000075",
          '&:hover': {
          backgroundColor: 'primary.main',
          opacity: [0.9, 0.8, 0.7],
        },

        // width:"100%",
        // height:"100%",
      }}>
      <Typography color="white" >
      {this.truncate(item.name,100)}
    </Typography>
    <br/>


    {this.getUrl(item).includes("cuhet-backend2.net/") ?
    <img
    alt="panorama"
    src="/images/panoWhite.png"
    style={{
    position: "relative",
    width: "36px",
    maxHeight: "36px",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  }}
/>


: <VisibilityIcon fontSize="medium" sx={{ color: "white" }} /> }

</CardActionArea>
</Card>
</div>
</ListItem>
))}
</List>*/}
</Grid>
<Grid item xs={1} sx={{
  position: "relative",
  right:"20px",
}}>
{this.state.itemsView.length>4 &&
  <IconButton
    color="primary"
    aria-label="ArrowForward"
    component="label"
    size="large"
    sx={{
      top: "65px",
    }}
    variant="contained"
    onClick={() => this.scrollRight()}
    disabled={this.state.disabledRight}
    >
      <ArrowForwardIosIcon fontSize="large" sx={{
        borderRadius: 50,
        backgroundColor: "white",
        height:90
      }}/>
    </IconButton>
  }
</Grid>
</Grid>
:
<Box sx={{
  bottom:"0%",
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  position: 'absolute',
  width: "100%",
  // m:2,

  // paddingBottom:"33px",
}}>
<SwipeableViews
  style={{
    width:"250px",
    display:"inline-block",
  }}
  index={this.state.activeStep}
  onChangeIndex={this.handleStepChange}
  enableMouseEvents>
  {this.state.itemsView.length>0 &&this.state.itemsView.map((item, index)=>(
    <Card onClick={()=>this.openCard(item)}
      sx={{
        border:  this.state.singleItem._id===item._id ? "4px solid blue" : "1px solid gray",
        position:"relative",
        width:"250px",
        height:"130px",
        //backgroundColor: this.state.singleItem._id===item._id ? "lightblue" : "trasparent"
      }}>

      <CardMedia
        component="img"
        height="100%"
        width="100%"
        image={
          item.uriCoverExternalRes === '' && item.type === "poi"
          ? "/images/poi.jpg"
          :
          item.uriCoverExternalRes === '' && item.type === "path"
          ? "/images/path.jpg"
          : item.uriCoverExternalRes
        }
        alt={item.name}
      />

      <CardActionArea sx={{
        textAlign: 'center',
        position:"absolute",
        top:"0",
        height:"100%",
        width:"100%",
        backgroundColor: "#00000075",
        '&:hover': {
          backgroundColor: 'primary.main',
          opacity: [0.9, 0.8, 0.7],
        },
      }}>
      <Typography color="white" >
        {this.truncate(item.name,64)}
      </Typography>
      <br/>
      {/* <VisibilityIcon fontSize="medium" sx={{ color: "white" }} /> */}
      {this.getUrl(item).includes("cuhet-backend2.net/") ?
      <img
        alt="panorama"
        src="/images/panoWhite.png"
        style={{
          position: "relative",
          width: "36px",
          maxHeight: "36px",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      />


      : <VisibilityIcon fontSize="medium" sx={{ color: "white" }} /> }
    </CardActionArea>

    {/* </Tooltip>

      <Tooltip title={item.name}>
      <CardContent sx={{
      textAlign: 'center',
      backgroundColor:"black"
    }}>
    <Typography gutterBottom variant="h5" component="div"
    sx={{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }}>
  {item.name}
</Typography> */}
{/* <Typography variant="body2" color="text.secondary">
{item.location!==undefined && this.getDistance(item)} km
</Typography> */}
{/* </CardContent>
</Tooltip>
</CardActionArea> */}
</Card>
))}
</SwipeableViews>
{this.state.itemsView.length>0 &&
  <MobileStepper
    variant="text"
    steps={this.state.itemsView.length}
    position="static"
    activeStep={this.state.activeStep}
    style={{
      // width:"250px"
      margin:"5px 15px 30px 15px",

    }}
    nextButton={
      <Button
        size="small"
        onClick={this.handleNext}
        disabled={this.state.activeStep === this.state.itemsView.length - 1}
        >
          Next
          {window.document.dir === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      }
      backButton={
        <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0}>
          {window.document.dir  === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          Back
        </Button>
      }
    />
  }
</Box>
}

</>
)
}

}

export default connect(mapStateToProps, mapDispatchToProps)(MapList);
