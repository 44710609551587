import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import APP from "../redux/actions";
import { connect } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import {
  getResourceDataForLanguage,
} from "../utils/utility";
import {
  // Parallax,
  ParallaxBanner,
  // ParallaxBannerLayer
} from 'react-scroll-parallax';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import useScrollTrigger from "@mui/material/useScrollTrigger";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
//import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import IconButton from '@mui/material/IconButton';
// import LaunchIcon from '@mui/icons-material/Launch';
import ShareIcon from '@mui/icons-material/Share';
// import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import NearMeIcon from '@mui/icons-material/NearMe';

import './ContentList.css'


import {
  EmailShareButton,
  FacebookShareButton,
  // HatenaShareButton,
  // InstapaperShareButton,
  // LineShareButton,
  // LinkedinShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  // PinterestShareButton,
  // PocketShareButton,
  // RedditShareButton,
  // TelegramShareButton,
  // TumblrShareButton,
  TwitterShareButton,
  // ViberShareButton,
  // VKShareButton,
  WhatsappShareButton,
  // WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  // FacebookMessengerIcon,
  // HatenaIcon,
  // InstapaperIcon,
  // LineIcon,
  // LinkedinIcon,
  // LivejournalIcon,
  // MailruIcon,
  // OKIcon,
  // PinterestIcon,
  // PocketIcon,
  // RedditIcon,
  // TelegramIcon,
  // TumblrIcon,
  TwitterIcon,
  // ViberIcon,
  // VKIcon,
  // WeiboIcon,
  WhatsappIcon,
  // WorkplaceIcon
} from "react-share";


import Tooltip from '@mui/material/Tooltip';




const theme = createTheme();

theme.typography.h4 = {
  fontSize: '1.4rem',
  '@media (min-width:600px)': {
    fontSize: '1.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.6rem',
  },
};

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  APP: (payload) => dispatch(APP(payload))
});

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 600
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-top',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'start',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        //role="presentation"
        sx={{
          position: 'fixed',
          width: "50%",
          left: '0',
          right: "0",
          top:"70px",
          margin:"0 auto"

        }}
        >
          {children}
        </Box>
      </Fade>
    );
  }

  class ContentList extends Component {

    constructor(props) {
      super(props);
      this.state = {
        open:false,
        setOpen:false,
        scrollY:false,
      }
    }


    componentDidMount(){
      // console.log(this.props.items);

    }

    handleScroll=(e)=>{
      // console.log(e.deltaY);
      // e.preventDefault();
      if(e.deltaY< 15){
        this.setState({scrollY:true})
      }

      else{
        this.setState({scrollY:false})
      }
    }

    checkDevice = () => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        // Take the user to a different screen here.
        // console.log(navigator.userAgent)
        return 'Mobile'

      }else return "Desktop"
    }

    getDistance = (item) =>{

      // ============================================================
      // FROM: https://www.movable-type.co.uk/scripts/latlong.html
      // This uses the ‘haversine’ formula to calculate the great-circle distance between two points – that is, the shortest distance over the earth’s surface – giving an ‘as-the-crow-flies’ distance between the points (ignoring any hills they fly over, of course!).
      // Haversine
      // formula: 	a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
      // c = 2 ⋅ atan2( √a, √(1−a) )
      // d = R ⋅ c
      // where 	φ is latitude, λ is longitude, R is earth’s radius (mean radius = 6,371km);
      // note that angles need to be in radians to pass to trig functions!
      // JavaScript:
      // const R = 6371e3; // metres
      // const φ1 = lat1 * Math.PI/180; // φ, λ in radians
      // const φ2 = lat2 * Math.PI/180;
      // const Δφ = (lat2-lat1) * Math.PI/180;
      // const Δλ = (lon2-lon1) * Math.PI/180;
      //
      // const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
      // Math.cos(φ1) * Math.cos(φ2) *
      // Math.sin(Δλ/2) * Math.sin(Δλ/2);
      // const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      //
      // const d = R * c; // in metres
      // ============================================================


      let R = 6371e3; // metres
      let φ1 = this.props.configuration.position.lat * Math.PI/180; // φ, λ in radians
      let φ2 = item.location.coordinates[1] * Math.PI/180;
      let Δφ = (item.location.coordinates[1]-this.props.configuration.position.lat) * Math.PI/180;
      let Δλ = (item.location.coordinates[0]-this.props.configuration.position.lng) * Math.PI/180;

      let a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ/2) * Math.sin(Δλ/2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

      let d = R * c; // in metres

      let KM = (Math.round(d) /1000) ;
      // return d.toFixed(0)/1000;
      return KM
    }

    openItemMap = (item) =>{  
     
      let latItem= item.location.coordinates[1]+",";
      let lngItem= item.location.coordinates[0];
     
    if (this.props.positionenabled=== true){
      var mapLocationUrl = "https://maps.google.com/maps?saddr=current location&daddr=" + latItem + lngItem;
      }else {
            let lat = this.props.configuration.position.lat+",";
            let lng =   this.props.configuration.position.lng;
            var mapLocationUrl = "https://maps.google.com/maps?saddr="+lat+lng+"&daddr="+ latItem + lngItem;
            }
      console.log("mapLocationUrl:" + mapLocationUrl)
      window.open(encodeURI(mapLocationUrl), '_system', 'location=yes');
    }

    openCard = (item) =>{
      let selectedUri= item.url;
      let url="";
      if (typeof(selectedUri)==='string'){ //retrocompatibilità
        url = selectedUri;
      }else {
        let itemHELP={};
        itemHELP.medialan=selectedUri;
        let lang =  getResourceDataForLanguage(itemHELP, "en");
        url = selectedUri[lang];
      }


      let detectDeviceType =
      //this.checkDevice();
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ? 'Mobile'
      : 'Desktop';
      // console.log(detectDeviceType); // "Mobile" or "Desktop"

      if(detectDeviceType==="Desktop")
      window.open(url, "_blank");
      else
      window.open(url, "_self");
    }

    getUrl = (item) => {
      let selectedUri= item.url;
      let url="";
      if (typeof(selectedUri)==='string'){ //retrocompatibilità
        url = selectedUri;
      }else {
        let itemHELP={};
        itemHELP.medialan=selectedUri;
        let lang =  getResourceDataForLanguage(itemHELP, "en");
        url = selectedUri[lang];
      }
      return url;
    }


    render(){
      return(

        this.props.progress===true ?

        <CircularProgress sx={{
          p: 10,
        }}/> :

        this.props.progress===false && this.props.items.length===0
        ?

        <>
        <Typography sx={{
          p: 10,
        }}
        gutterBottom
        variant="h5"
        component="div"
        >
          SORRY, NO ITEMS FOUND
        </Typography>
      </>
      :
      <Grid container
        direction="row"
        item p={0}
        // onWheel={this.handleScroll}
        // onWheel={this.handleScroll, {passive:true}}
        >


          <div className="gradient">

            {this.checkDevice()==="Mobile" && window.matchMedia("only screen and (max-width: 560px)").matches ? <img src="./images/static_image_portrait.jpeg"
            alt="background"
            // className={this.checkDevice()==="Mobile" ? 'mobile' : 'desktop' }
            style={{
              // width:this.checkDevice()==="Mobile" ? "auto" : "100vw",
              width:"100vw",
              height:"100vh",
              position:"relative",
              zIndex:-1,
              display:"block",
            }}
            ></img> :
            <img src="./images/static_image.jpeg"
            alt="background"
            // className={this.checkDevice()==="Mobile" ? 'mobile' : 'desktop' }
            style={{
              // width:this.checkDevice()==="Mobile" ? "auto" : "100vw",
              width:"100vw",
              height:"100vh",
              position:"relative",
              zIndex:-1,
              display:"block",
            }}
            ></img> }


            {/* <img src="./images/static_image.jpeg"
            alt="background"
            // className={this.checkDevice()==="Mobile" ? 'mobile' : 'desktop' }
            style={{
            // width:this.checkDevice()==="Mobile" ? "auto" : "100vw",
            width:"100vw",
            height:"100vh",
            position:"relative",
            zIndex:-1,
            display:"block",
          }}
          ></img> */}



        </div>


        <div style={{position: 'absolute',
          top: this.checkDevice()==="Mobile" ? "10%" :"30%",
          // top: 70,
          width:"100%",
          // paddingLeft:"25%",
          // paddingRight:"25%",
        }}>
        <div>
          <img style={{
            width: this.checkDevice()==="Mobile" ? "100px" : "",
            height:this.checkDevice()==="Mobile" ? "100px" : "",
          }} src="./images/logo_ufficiale3.png" alt="IMG"></img>
        </div>
        <ThemeProvider theme={theme}>
          <Typography
            component="div"
            variant="h4"
            >
              <span style={{
                color: '#FFFFFF'
              }}>
              Immersive exploration of italian cultural heritage
            </span>
          </Typography>
        </ThemeProvider>
      </div>


      <div className="mouse"
        style={{
          right: this.checkDevice()==="Mobile" ? "43%" :"48%",
        }}>
        <p id="blinka">scroll</p>
      </div>

      {/* <IconButton variant="outlined" sx={{color: '#FFFFFF'}}>
      <ShareIcon style={{fontSize:"60px"}}/>
    </IconButton> */}
    {/* <ShareIcon className="share" color='primary' style={{fontSize:"40px"}}/>*/}


    <SpeedDial
      ariaLabel="Share"
      sx={{
        position: 'absolute',
        // color: "primary",
        bottom: 16,
        right: this.checkDevice()==="Mobile" ? "9px" :"16px",
        '& .MuiFab-primary': {
          backgroundColor: '#FFFFFF99',
          // color: "blue",
          width: 40,
          height: 40,
          '&:hover': {backgroundColor:"#1565c0!important"}
        },
        '& .MuiSpeedDialIcon-icon': { fontSize: 10 },
        '&:hover': {

          '& .MuiSvgIcon-colorPrimary': {
            color: 'white!important',
            backgroundColor:"transparent"
          },
        }
      }}
      icon={ <ShareIcon
        sx={{

          // '& .MuiSvgIcon-colorPrimary': {
          //   '&:hover': {color: 'white!important'}
          // },
          // backgroundColor: '#FFFFFF99',
          // '&:hover': {color: '#FFF'}
        }}
        color='primary'
      />}
      >


        <SpeedDialAction
          key="Facebook"
          icon={
            <div>
              <FacebookShareButton
                url={window.location.href}
                >
                  <FacebookIcon size={38} round style={{marginTop:"8px"}}/>
                </FacebookShareButton>
              </div>
            }
            tooltipTitle="Facebook"
          />



          <SpeedDialAction
            key="Twitter"
            icon={
              <div>
                <TwitterShareButton
                  url={window.location.href}
                  >
                    <TwitterIcon size={38} round style={{marginTop:"8px"}}/>
                  </TwitterShareButton>
                </div>
              }
              tooltipTitle="Twitter"
            />


            <SpeedDialAction
              key="Email"
              icon={
                <div>
                  <EmailShareButton
                    separator=" "
                    url={window.location.href}
                    subject="IDEHA - Immersive exploration of italian cultural heritage"
                    body="IDEHA - Immersive exploration of italian cultural heritage on"
                    >
                      <EmailIcon size={38} round style={{marginTop:"8px"}}/>
                    </EmailShareButton>

                  </div>
                }
                tooltipTitle="Email"
                iconshare="EmailShareButton"
              />



              {this.checkDevice()==="Mobile" ?

              <SpeedDialAction
                key="Whatsapp"
                icon={
                  <div>
                    <WhatsappShareButton
                      url={window.location.href}
                      >
                        <WhatsappIcon size={38} round style={{marginTop:"8px"}}/>
                      </WhatsappShareButton>
                    </div>
                  }
                  tooltipTitle="Whatsapp"
                  iconshare="WhatsappShareButton"
                />
                :<></>}
              </SpeedDial>

              {this.props.items.length>0 &&this.props.items.map((item, index)=>(
                <div key={index} onClick={()=>this.openCard(item)}>

                  <>
                  <ParallaxBanner
                    // disabled
                    layers={[
                      {
                        image: item.uriCoverExternalRes === '' && item.type === "poi"
                        ? "/images/poi.jpg"
                        :
                        item.uriCoverExternalRes === '' && item.type === "path"
                        ? "/images/path.jpg"
                        : item.uriCoverExternalRes,

                        speed: -15,
                        style: {
                          // backgroundSize:"cover",
                          // backgroundRepeat:"no-repeat",
                          // backgroundAttachment:"fixed",
                          // width:"100vw",
                          // backgroundPosition:"center"
                        },
                        translateY: [-10, 20, 'easeInOut'],
                        scale: [1, 1.2, 'easeOutBack'],
                      },
                    ]}
                    style={{
                      width:"100vw",
                      height:"100vh",
                      // position:"absolute"
                    }}
                    >

                      <div style={{ display: "flex",
                        justifyContent: index %2 === 1 ?  "right" : "left"}}>
                        <div style={{
                          background: "#0006",
                          color: "#f1f1f1",
                          width: this.checkDevice()==="Mobile" ? "100%" :"45%",
                          padding: "2px",
                          margin:this.checkDevice()==="Mobile" ? "0" :"10px",
                          textAlign: "left",
                          position:"absolute",
                          bottom:"0px",
                          // borderRadius: "10px"
                        }}>

                        <div>
                          <h4
                            style={{
                              fontWeight: "normal",
                            }}>
                            {item.name}
                          </h4>

                          <div className="inline">
                            <h5 style={{
                              fontWeight: "normal",
                              fontStyle: "italic",
                              backgroundColor: "#00000061",
                              width: "100px",
                            }}>
                            {this.getDistance(item)} km
                          </h5>
                        </div>
                        <div className="inline">
                          <IconButton
                            color="inherit"
                            // variant="outlined"
                            // aria-label="open drawer"
                            onClick={()=>this.openItemMap(item)}
                            edge="start"
                            sx={{
                              pointerEvents: "pointer",
                              // backgroundColor: "#00000061",
                              // backgroundColor: '#FFFFFF99',
                              // borderRadius:0,
                              ml: 0,
                              ...(this.state.openDrawer && { display: 'none' })
                            }}
                            ><Tooltip title="Go to">
                              <NearMeIcon />
                              </Tooltip>
                            </IconButton>
                          </div>
                          <div className="inline">
                            {this.getUrl(item).includes("cuhet-backend2.net/") ?
                            <img
                              alt="panorama"
                              src="/images/pano-color-fff.png"
                              style={{
                                // position: "relative",
                                // width: "200px",
                                // maxHeight: "300px",
                                // backgroundSize: "cover",
                                // backgroundPosition: "center center",
                                // top: "30%",
                                position: "absolute",
                                width: "228px",
                                right: "0px",
                                bottom: "5px"
                              }}
                            />
                            : <></>}
                          </div>
                        </div>

                      </div>
                    </div>
                    {/* {this.getUrl(item).includes("cuhet-backend2.net/") ?
                    <img
                    alt="panorama"
                    src="/images/pano-color.png"
                    style={{
                    position: "relative",
                    width: "300px",
                    maxHeight: "300px",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    top: "30%",
                  }}
                />
                : <></>} */}
              </ParallaxBanner>
            </>
            {/* } */}

          </div>

        ))}
        {/* <div style={{backgroundColor:"red"}}> */}
        <ScrollTop {...this.props} >
          <Fab size="small" aria-label="scroll back to top"
            style={{
              backgroundColor: "#e0e0e070",
            }}>
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>

        {/* </div> */}
      </Grid>


    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ContentList);
